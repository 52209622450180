import React from 'react';
import { css } from '@emotion/react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';

const projects = [
  {"name":"DocuSign.com","url":"http://www.docusign.com","img":"https://i.imgur.com/I27bO0N.jpg","description":"A re-platform of DocuSign's website from Drupal to React via Nextjs. An entire rebuild and migration from Drupal to a modern CMS driven React app platform, from a design system through to CI-CD pipelines.","langs":["TypeScript","React","Emotion", "Nextjs", "Nx"]},
  {"name":"DocuSign Checkout","url":"http://ecom.docusign.com","img":"https://i.imgur.com/EFQ7pCy.png","description":"A re-platform of DocuSign's checkout process. A new graphQL API and React frontend to internalize the data flow for people buying DocuSign products.","langs":["TypeScript","React","GraphQL","Emotion"]},
  {"name":"TAG website","url":"http://new.thirdandgrove.com","git":"https://github.com/thirdandgrove/tagd8_gatsby","img":"https://i.imgur.com/ZIhpOJA.png","description":"Third and Grove is primarily a Drupal agency. Our website is a headless Drupal project with a Gatsby front end. Styled with emotion (a css-in-js library) and developed alongside our Gatsby Drupal live preview feature.","langs":["JavaScript","React","Gatsby","GraphQL","Emotion"]},
  {"name":"Gatsby Theme Drupal","url":"https://www.npmjs.com/package/gatsby-theme-drupal","git":"https://github.com/thirdandgrove/gatsby-theme-drupal","img":"https://raw.githubusercontent.com/thirdandgrove/gatsby-theme-drupal/master/readme-preview.png","description":"A Gatsby theme to allow Drupal users new to Gatsby to easily get acquainted with building headlessly against a Drupal instance","langs":["JavaScript","React","Gatsby","GraphQL"]},
  {"name":"JS Notes","url":"https://www.npmjs.com/package/jsnotes","git":"https://github.com/grantglidewell/jsnotes","img":"https://i.imgur.com/KjTPceh.png","description":"I get tired of switching between apps and spend a lot of time on that command line. so I wrote this command line application for taking notes that syncs with the Todoist API","langs":["JavaScript","TypeScript","Node"]},
  {"name":"Zesty.io Design System","url":"https://github.com/zesty-io/design-system","git":"https://github.com/zesty-io/design-system","img":"https://i.imgur.com/MQZ5TCk.png","description":"Design system for building user interfaces in Zesty.io's ecosystem. Follows the Atomic design guidelines set forth by Brad Frost. ","langs":["React","Redux","Less","Webpack"]},
  {"name":"Zesty.io Accounts","url":"https://accounts.zesty.io/login","git":"https://github.com/zesty-io/accounts-ui","img":"https://i.imgur.com/0Wdgi4G.png","description":"Account management app for Zesty web CMS. Allows users to create accounts, start zesty.io instances, manage permissions and instance settings in a convenient, fast and responsive React app.","langs":["React","Redux","Less","Webpack"]},
]

const Work = () => {
  return (
    <Layout title='Work'>
      {projects.map(project => (
        <div
          css={css`
            display: flex;
            align-self: center;
            margin-top: 15px;
            border-bottom: 1px solid black;
            margin-bottom: 15px;
          `}
          key={project.url}
        >
          <span
            css={css`
              padding: 15px;
              @media (max-width: 800px) {
                display: none;
              }
              img {
                max-height: 350px;
                max-width: 350px;
              }
            `}
          >
            <img src={project.img} alt={project.name} />
          </span>
          <span
            css={css`
              width: 350px;
              a {
                text-decoration: underline;
                display: flex;
                padding-right: 15px;
                justify-content: center;
              }
              h2 {
                text-align: center;
              }
            `}
          >
            <a href={project.url} rel='noopener noreferrer' target='_blank'>
              <h2>{project.name}</h2>
            </a>
            <p
              css={css`
                padding-bottom: 15px;
                font-weight: 300;
              `}
            >
              {project.description}
            </p>
            <strong>{project.langs.join(' ◦ ')}</strong>
            {project.git && <a href={project.git} rel='noopener noreferrer' target='_blank'>
              <img
                src='/GitHub-Mark-32px.png'
                alt='GitHub'
                css={css`
                  height: 32px;
                  margin: 15px 0 5px 0;
                `}
              />
            </a>}
          </span>
        </div>
      ))}
    </Layout>
  );
};

export default Work;